import React from "react"
import { GatsbyImage } from "gatsby-plugin-image"

export default function HeaderStrip({ strip }) {
  if (strip?.showStrip === true) {
    return (
      <section style={{ backgroundColor: strip.stripColour }} className="p-3">
        <div className="wrapper flex h-full items-center justify-center">
          <GatsbyImage
            image={strip.stripLogo.asset.gatsbyImageData}
            alt={strip.stripLogo.altText || ""}
            className="h-12 max-w-sm"
            imgStyle={{ objectFit: `contain` }}
          />
        </div>
      </section>
    )
  }
}
